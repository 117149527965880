










import { defineComponent, ref, watch } from "@/composition-api";
import { mixin as clickaway } from "vue-clickaway";
import debounce from "lodash.debounce";
import store from "@/store";
import UiAutocomplete from "@dpa-id-components/ui-autocomplete";
import useFilterSpeakers from "./useFilterSpeakers";
import useMatomoEvent from "@/composition/useMatomoEvent";
import api from "@/api";

export default defineComponent({
  mixins: [clickaway],
  components: { UiAutocomplete },
  setup() {
    const speakerSearch = ref("");
    const options = ref([]);

    /* Search input and speaker filter rule logic */
    const { firstSpeaker, setSpeaker } = useFilterSpeakers();

    const handleSelect = value => {
      setSpeaker(value);
      useMatomoEvent({
        category: "Advanced Search",
        action: "Select Speaker",
        name: "Speaker"
      });
    };

    watch(
      firstSpeaker,
      () => {
        if (firstSpeaker.value) {
          speakerSearch.value = firstSpeaker.value?.values[0];
        }
      },
      { immediate: true }
    );

    watch(
      speakerSearch,
      debounce(async () => {
        if (!speakerSearch.value && firstSpeaker.value) {
          store.commit("filterRules/removeFilterRule", {
            filterRule: firstSpeaker.value
          });
        }

        if (
          speakerSearch.value.length > 2 &&
          speakerSearch.value !== firstSpeaker.value?.values?.[0]
        ) {
          const url = `audioitems/valuesForFieldMatching?fieldName=person&fragment=${speakerSearch.value}`;
          const data = await api.get({
            url,
            authJWT: store.state.auth.token,
          });
          options.value = data;
        } else {
          options.value = [];
        }
      }, 250)
    );

    return {
      speakerSearch,
      options,
      handleSelect
    };
  }
});
